import gql from "graphql-tag";


export const GET_ALL_USER = gql`
 query {
  GetAllUser {
    Id
    Name
    Username
  }
}
`;