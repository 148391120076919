import React from "react";
import { Query } from "react-apollo";

// core components

import UpdateFormInventario from "components/EditForm/UpdateFormInventory.js";

import { GET_INVENTORY } from "../../queries/inventarioQuery.js";

export class EditFormInventario extends React.Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    const id = this.props.id;
    return (
      <Query
        query={GET_INVENTORY}
        variables={{ id }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>loading</div>;
          if (error) return `Error!: ${error}`;

          return <UpdateFormInventario id={id} data={data} />;
        }}
      </Query>
    );
  }
}

export default EditFormInventario;
