import React from "react";
import ReactDOM from "react-dom";
import history from "./history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { SnackbarProvider } from "notistack";

import AuthLayout from "layouts/Auth.jsx";

import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";

/*
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  uri: "http://localhost:8080/graphql"
});

*/

const client = new ApolloClient({
  uri: "https://id.verpelli.it:58000/graphql",
  //uri: "http://192.168.1.41:8080/graphql",
  cache: new InMemoryCache(),

  request: async operation => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token
      }
    });
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <SnackbarProvider maxSnack={1}>
      <Router history={history}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Redirect from="/" to="/auth/login-page" />
        </Switch>
      </Router>
    </SnackbarProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
