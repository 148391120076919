import { graphql } from 'react-apollo'
import { Query } from 'react-apollo'
import { compose } from 'react-apollo'

import { GET_ALL_TIPOLOGIE } from '../../queries/anagraficaTipologieQuery.js'

import React from 'react'
import { Mutation } from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import { ApolloConsumer } from 'react-apollo'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import CardHeader from 'components/Card/CardHeader.jsx'
import CardText from 'components/Card/CardText.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import { INSERT_INVENTORY } from '../../queries/inventarioQuery.js'
import { GET_ALL_INVENTORY } from '../../queries/inventarioQuery.js'

import { withSnackbar } from 'notistack'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

class InsertFormInventory extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      Matricola: 0,
      Rfid: '',
      Tipologia: '',
      Already: false
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleFormSubmit()
    }
  }
  verifyUrl (value) {
    try {
      new URL(value)
      return true
    } catch (_) {
      return false
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail (value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }
  // function that verifies if a string has a given length or not
  verifyLength (value, length) {
    if (value.length >= length) {
      return true
    }
    return false
  }
  // function that verifies if two strings are equal
  compare (string1, string2) {
    if (string1 === string2) {
      return true
    }
    return false
  }
  // function that verifies if value contains only numbers
  verifyNumber (value) {
    var numberRex = new RegExp('^[0-9]+$')
    if (numberRex.test(value)) {
      return true
    }
    return false
  }
  change (event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'checkbox':
        if (event.target.checked) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'min-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      default:
        break
    }
    switch (type) {
      case 'checkbox':
        this.setState({ [stateName]: event.target.checked })
        break
      default:
        this.setState({ [stateName]: event.target.value })
        break
    }
  }

  handleFormSubmit = () => {
    var elements = document.getElementById('editForm').elements
    var ok = true
    for (var i = 0, element; (element = elements[i++]);) {
      if (element.type === 'text' && element.value === '') {
        console.log('Elemento non trovato')
        ok = false
      }
    }

    if (!ok) {
      this.props.enqueueSnackbar(`Inserire tutti i dati`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return false
    }

    document
      .getElementById('editForm')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  }

  render () {
    const { classes } = this.props
    if ((typeof this.props.data.GetAllInventario !== 'undefined') && !this.state.Already) {
      const maxMatricola =
        +Math.max(
          ...this.props.data.GetAllInventario.map(link => link.Matricola),
          0
        ) + 1

      if (this.state.Matricola !== maxMatricola) {
        this.setState({ 
          Matricola: maxMatricola,
          Already: true
           })
      }
    }

    const Items = () => (
      <Query query={GET_ALL_TIPOLOGIE}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`

          return (
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.Tipologia}
              onChange={this.handleSimple}
              inputProps={{
                name: 'Tipologia',
                id: 'tipologia'
              }}
            >
              {data.GetAllTipologie.map(link => (
                <MenuItem
                  key={link.Id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={link.Tipo}
                >
                  {link.Tipo}
                </MenuItem>
              ))}
            </Select>
          )
        }}
      </Query>
    )

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' text>
              <CardText color='rose'>
                <h4 className={classes.cardTitle}>Inserimento inventario</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <ApolloConsumer>
                {client => (
                  <Mutation
                    mutation={INSERT_INVENTORY}
                    onCompleted={() => {
                      this.props.enqueueSnackbar('Inserimento riuscito', {
                        variant: 'success',
                        autoHideDuration: 1000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    }}
                  >
                    {(updateQuery, { loading, error, data }) => {
                      return (
                        <form
                          id='editForm'
                          name='editForm'
                          onSubmit={e => {
                            e.preventDefault()

                            updateQuery({
                              variables: {
                                m: `${this.state.Matricola}`,
                                t: `${this.state.Tipologia}`,
                                r: `${this.state.Rfid}`
                              }
                            })
                              .then(response => {
                                this.setState({
                                  Matricola: this.state.Matricola+1,
                                  Rfid: '',
                                  Tipologia: '',
                                 
                                })

                                this.setState({
                                  ...response[Object.keys(response)[0]]
                                })
                                console.log(response)

                                client.query({
                                  query: GET_ALL_INVENTORY,
                                  fetchPolicy: 'network-only'
                                })
                              })
                              .catch(error => {
                                var err = ''
                                if (error.message.includes('connection')) {
                                  err = 'Errore connessione'
                                } else {
                                  if (error.message.includes('rfid_UNIQUE')) {
                                    err = 'Presenza duplicato (Rfid)'
                                  }
                                  if (
                                    error.message.includes('tipologia_UNIQUE')
                                  ) {
                                    err = 'Presenza duplicato (Tipologia)'
                                  }
                                  if (
                                    error.message.includes('matricola_UNIQUE')
                                  ) {
                                    err = 'Presenza duplicato (Matricola)'
                                  }
                                }

                                this.props.enqueueSnackbar(`${err}`, {
                                  variant: 'error',
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center'
                                  }
                                })
                              })
                          }} // on submit
                        >
                          <CustomInput
                            labelText='Matricola *'
                            id='matricola'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'Matricola', 'text'),
                              type: 'text',
                              value: `${this.state.Matricola}`,
                              onKeyPress: this.handleKeyPress
                            }}
                          />

                          <InputLabel
                            htmlFor='tipologia'
                            className={classes.selectLabel}
                          >
                            Tipologia
                          </InputLabel>
                          {'   '}

                          {Items()}

                          <CustomInput
                            labelText='Rfid *'
                            id='rfid'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'Rfid', 'text'),
                              type: 'text',
                              value: `${this.state.Rfid}`,
                              onKeyPress: this.handleKeyPress
                            }}
                          />
                        </form>
                      )
                    }}
                  </Mutation>
                )}
              </ApolloConsumer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button onClick={this.handleFormSubmit} color='rose'>
                Salva
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    ) // return
  } // render
}

export default withSnackbar(
  compose(
    graphql(GET_ALL_TIPOLOGIE),
    graphql(GET_ALL_INVENTORY)
  )(withStyles(styles)(InsertFormInventory))
)
