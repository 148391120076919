import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
  const { classes, white } = props;
 
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });


  return (
    <footer className={classes.footer} >
     <div style={{textAlign: 'center'}} >
       
        <p>
          &copy; {1900 + new Date().getYear()}{" "}
          <a href="http://www.uberticonsulting.com" className={anchor}>
            Uberti Consulting
          </a>
        
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
