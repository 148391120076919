import { graphql,compose } from "react-apollo";
import { GET_ALL_TIPOLOGIE,DELETE_TIPOLOGIE } from "../../queries/anagraficaTipologieQuery.js";

import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import EditFormTipologie from "components/EditForm/EditFormTipologie.js";
//import MutationFormTipologie from "components/EditForm/MutationFormTipologie.js"


import SweetAlert from 'react-bootstrap-sweetalert'
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOpen: true,
      editOpen: false,
      key: 0,
      id: 0,
      alertCode: null,
      show: true
    };
  }

  toggleEdit(key) {
    const { tableOpen, editOpen } = this.state;
    this.setState({ tableOpen: !tableOpen, editOpen: !editOpen, key: key });
    //if (!tableOpen) { this.props.data.refetch()}
  }

  hideAlert = () => {
    this.setState({
      alertCode: null
    })
  }
  

  successDelete = data => {
    this.props.mutate({
      mutation: DELETE_TIPOLOGIE,
      variables: {
        id: data.Id,
        tipologia: data.Tipo,
        descrizione: data.Descrizione,
      },
      onCompleted: this.hideAlert()
    })

    this.setState({
      alertCode: null
    })
  }

  showAlert (key) {
    this.setState({
      alertCode: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title='Sei sicuro?'
          onConfirm={() =>
            this.successDelete(this.props.data.GetAllTipologie[key])
          }
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText='Si'
          cancelBtnText='No'
          showCancel
        >
          Cancellazione tipologia :{' '}
          {this.props.data.GetAllTipologie[key].Tipo} <br />
          Attenzione: la cancellazione sarà definitiva
        </SweetAlert>
      )
    })
  }


  render() {
    if (this.props.data.loading) return <div>Loading...</div>;

    const { tableOpen, editOpen } = this.state;
    //Editing
    if (!tableOpen && editOpen) {
      const { key } = this.state;
      const { classes } = this.props;
      const id = this.props.data.GetAllTipologie[key].Id;
      return (
        <div>
          {" "}
          <Button
            className={classes.marginRight}
            onClick={() => this.toggleEdit(key)}
          >
            <KeyboardArrowLeft className={classes.icons} /> Anagrafica tipologie
          </Button>
          <EditFormTipologie id={id} />
        </div>
      );
    }

    //Normal view
    if (tableOpen && !editOpen) {
      const { classes } = this.props;
      const data = this.props.data.GetAllTipologie;

      return (
         <div>
        {this.state.alertCode}

        
        <GridContainer>
          
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Anagrafica Tipologie</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data.map((prop, key) => {
                    return {
                      id: prop.Id,
                      tipologia: prop.Tipo,
                      descrizione: prop.Descrizione,
                      actions: (
                        <div className="actions-right">
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.toggleEdit(key);
                            }}
                            color="warning"
                            className="edit"
                          >
                            <Dvr />
                          </Button>{" "}
                          {/* use this button to remove the data row */}
                          <Button
                            justIcon
                            round
                            simple
                             onClick={() => {
                              this.showAlert(key)
                            }}
                            color="danger"
                            className="remove"
                          >
                            <Close />
                          </Button>{" "}
                        </div>
                      )
                    };
                  })}
                  filterable
                  columns={[
                    {
                      Header: "Tipologia",
                      accessor: "tipologia"
                    },

                    {
                      Header: "Descrizione",
                      accessor: "descrizione"
                    },
                    
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        </div>
      ); // return
    } // if tableOpen
  }
}

export default compose(
  graphql(GET_ALL_TIPOLOGIE),
   graphql(DELETE_TIPOLOGIE, {
    options: {
      refetchQueries: [
        {
          query: GET_ALL_TIPOLOGIE
        }
      ]
    }
  }
  
))(withStyles(sweetAlertStyle)(ReactTables));
