import gql from "graphql-tag";



export const INSERT_INVENTORY = gql`
 mutation  InsertInventoryElement($m: Int!, $t: String!, $r: String!)
{
  
  CreateInventario(matricola:$m,tipo:$t,rfid:$r) {
    Id
  }
  
 
}
`;

export const GET_INVENTORY = gql`
query GetInventory($id: Int!) {
  GetInventario(id: $id) {
    Id
    Matricola
    Tipo
    Rfid
  }
}
`;

export const GET_ALL_INVENTORY = gql`
  {
   GetAllInventario {
    Id
    Matricola
    Tipo
    Rfid
   
  }
  }
`;

export const UPDATE_INVENTORY = gql`
 mutation UpdateInventario (
  $id: Int!
  $matricola: Int!
  $rfid: String!
  $tipo: String!

) { UpdateInventario(
  id: $id
  matricola: $matricola
  rfid: $rfid
  tipo: $tipo
    
) {
   Id
    Matricola
    Tipo
    Rfid
}

}
`;


export const DELETE_INVENTORY = gql`
 mutation DeleteInventario (
  $id: Int!
  $matricola: Int!
  $rfid: String!
  $tipo: String!
  
) { DeleteInventario(
  id: $id
  matricola: $matricola
  rfid: $rfid
  tipo: $tipo
) 
{
  Id
}
}
`;
