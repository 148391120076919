import { graphql, Query, compose } from 'react-apollo'
import {
  GET_ALL_VERIFICHE,
  DELETE_VERIFICHE
} from '../../queries/verificheQuery.js'
import { GET_ALL_USER } from '../../queries/userQuery.js'
// import checkUser from '../../queries/queries'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { withSnackbar } from 'notistack'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

import EditFormVerifiche from 'components/EditForm/EditFormVerifiche.js'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

class VisualizzaVerificheConUtenti extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tableOpen: true,
      editOpen: false,
      key: 0,
      id: 0
    }
  }

  hideAlert = () => {
    this.setState({
      alertCode: null
    })
  }
  refetch = () => {
    // this.props.data.refetch({ forceFetch: true }).then(() => {
    this.hideAlert()
    // this.setState(this.state)
    // })
  }

  successDelete = data => {
    this.props.mutate({
      mutation: DELETE_VERIFICHE,
      variables: {
        id: data.Id,
        date: data.Date,
        matricola: data.Matricola,
        rfid: data.Rfid,
        stato: data.Stato,
        tipo: data.Tipo,
        userid: data.Userid
      },
      onCompleted: this.refetch()
    })

    this.setState({
      alertCode: null
    })
  }

  showAlert (key) {
    this.setState({
      alertCode: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title='Sei sicuro?'
          onConfirm={() =>
            this.successDelete(this.props.data.GetAllVerifiche[key])
          }
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText='Si'
          cancelBtnText='No'
          showCancel
        >
          Cancellazione verifica rfid :{' '}
          {this.props.data.GetAllVerifiche[key].Matricola} <br />
          Attenzione: la cancellazione sarà definitiva
        </SweetAlert>
      )
    })
  }

  toggleEdit (key) {
    const { tableOpen, editOpen } = this.state
    this.setState({ tableOpen: !tableOpen, editOpen: !editOpen, key: key })
    // if (!tableOpen) { this.props.data.refetch()}
  }

  correctDate (d) {
    return new Date(parseInt(d)).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
  }

  correctState (s) {
    if (s == 0) return 'Accettato'
    return 'Rifiutato'
  }

  returnUserName (userid) {
    var record = this.props.users.find(o => o.Id == userid, 0)
    return record.Username
  }

  render () {
    if (this.props.data.loading) return <div>Loading...</div>
    const { classes } = this.props

    const { tableOpen, editOpen } = this.state
    if (!tableOpen && editOpen) {
      const { key } = this.state
      const { classes } = this.props
      const id = this.props.data.GetAllVerifiche[key].Id

      return (
        <div>
          {' '}
          <Button
            className={classes.marginRight}
            onClick={() => this.toggleEdit(key)}
          >
            <KeyboardArrowLeft className={classes.icons} /> Visualizza verifiche
          </Button>
          <EditFormVerifiche id={id} />
        </div>
      )
    }

    return (
      <div>
        {this.state.alertCode}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='primary' icon>
                <CardIcon color='primary'>
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Verifiche effettuate</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.props.data.GetAllVerifiche.map((prop, key) => {
                    return {
                      id: prop.Id,
                      rfid: prop.Rfid,
                      stato: this.correctState(prop.Stato),
                      matricola: prop.Matricola,
                      tipologia: prop.Tipo,
                      utente: this.returnUserName(prop.Userid),
                      data: this.correctDate(prop.Date),
                      actions: (
                        <div className='actions-right'>
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.toggleEdit(key)
                            }}
                            color='warning'
                            className='edit'
                          >
                            <Dvr />
                          </Button>{' '}
                          {/* use this button to remove the data row */}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.showAlert(key)
                            }}
                            color='danger'
                            className='remove'
                          >
                            <Close />
                          </Button>{' '}
                        </div>
                      )
                    }
                  })}
                  filterable
                  columns={[
                    {
                      Header: 'Matricola',
                      accessor: 'matricola'
                    },
                    {
                      Header: 'Tipologia',
                      accessor: 'tipologia'
                    },
                    {
                      Header: 'Rfid',
                      accessor: 'rfid'
                    },
                    {
                      Header: 'Stato',
                      accessor: 'stato'
                    },
                    {
                      Header: 'Data',
                      accessor: 'data'
                    },
                    {
                      Header: 'Utente',
                      accessor: 'utente'
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom
                  className='-striped -highlight'
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    ) // return
  }
}

export default compose(
  graphql(GET_ALL_VERIFICHE),
  graphql(DELETE_VERIFICHE, {
    options: {
      refetchQueries: [
        {
          query: GET_ALL_VERIFICHE
        }
      ]
    }
  })
)(withStyles(sweetAlertStyle)(VisualizzaVerificheConUtenti))
