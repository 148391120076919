import gql from "graphql-tag";

export const GET_ALL_TIPOLOGIE = gql`
  {
   GetAllTipologie {
    Id
    Tipo
    Descrizione
  }
  }
`;

export const GET_TIPOLOGIE = gql`
query GetTipologia($id: Int!) {
  GetTipologie(id: $id) {
    Id
    Tipo
    Descrizione
  }
}
`;

export const UPDATE_TIPOLOGIA = gql`
 mutation UpdateTipologia (
  $id: Int!
  $tipologia: String!
  $descrizione: String

) { UpdateTipologie(
  id: $id
  tipo: $tipologia
  descrizione: $descrizione
) {
  Id
  Tipo
  Descrizione
}

}
`;


export const INSERT_TIPOLOGIA = gql`
mutation InsertTipologia(
  $tipologia: String!
  $descrizione: String
  
  ) {
  CreateTipologie(
    tipo:$tipologia
    descrizione: $descrizione
    ) {
    Id
  }
}
`;


export const DELETE_TIPOLOGIE = gql`
 mutation DeleteTipologia (
  $id: Int!
  $tipologia: String!
  $descrizione: String

) { DeleteTipologie(
  id: $id
  tipo: $tipologia
  descrizione: $descrizione
) {
  Id
  
}

}
`;