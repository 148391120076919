import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { graphql } from "react-apollo";
import checkUser from "../queries/queries";
import { Redirect } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "blue",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    try {
      if (e.history.location.pathname !== e.location.pathname) {
        this.refs.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    } catch {}
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes, userLevel) => {
    return routes.map((prop, key) => {
      if (prop.requiredLevel) {
        if (userLevel < prop.requiredLevel) {
          return null;
        }
      }
      if (prop.collapse) {
        return this.getRoutes(prop.views, userLevel);
      }

      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  //Remove route ( layout or path) with a requiredLevel
  removeRoute(routes, userLevel) {
    let innerRoutesLayout = routes.filter(route => {
      if (!route.requiredLevel) return route;
      if (route.requiredLevel < userLevel) return route;
    });

    let innerRoutes = innerRoutesLayout.filter(route => {
      if (route.views) {
        route.views = route.views.filter(innerRoute => {
          if (innerRoute.requiredLevel) {
            if (innerRoute.requiredLevel < userLevel) {
              return innerRoute;
            }
          } else return innerRoute;
        });
      }
      return route;
    });

    return innerRoutes;
  }
  render() {
    if (this.props.data.loading) {
      return <div>Loading</div>;
    }

    if (this.props.data.error) return <Redirect to="/auth/login-page" />;

    if (!this.props.data.error) {
      //Save user information

      let userLevel = 0;
      this.props.data.GetUser.User_groups.map(group => {
        if (group.Group_level > userLevel) {
          userLevel = group.Group_level;
        }
        return null;
      });

      const { classes, ...rest } = this.props;
      const mainPanel =
        classes.mainPanel +
        " " +
        cx({
          [classes.mainPanelSidebarMini]: this.state.miniActive,
          [classes.mainPanelWithPerfectScrollbar]:
            navigator.platform.indexOf("Win") > -1
        });
      return (
        <div className={classes.wrapper}>
          <Sidebar
            routes={this.removeRoute(routes, userLevel)}
            logoText={"Verpelli"}
            logo={logo}
            image={this.state.image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color={this.state.color}
            bgColor={this.state.bgColor}
            miniActive={this.state.miniActive}
            userLevel={userLevel}
            {...rest}
          />
          <div className={mainPanel} ref="mainPanel">
            <AdminNavbar
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              brandText={this.getActiveRoute(routes)}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>{this.getRoutes(routes, userLevel)}</Switch>
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                <Switch>{this.getRoutes(routes, userLevel)}</Switch>
                <CircularProgress />
              </div>
            )}
            {this.getRoute() ? <Footer fluid /> : null}
           
          </div>
        </div>
      );
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default graphql(checkUser, {
  options: {
    variables: {
      token: localStorage.getItem("token")
    }
  }
})(withStyles(appStyle)(Dashboard));
