import gql from "graphql-tag";


export default gql`
  query GetUserFromToken($t: String) {
    GetUser(token: $t) {
      Id
      Username
      User_groups {
        Id
        Group_name
        Group_level
      }
    }
  }
`;
