import React from "react";
import { Query } from "react-apollo";

// core components

import MutationFormTipologie from "components/EditForm/MutationFormTipologie.js";

import { GET_TIPOLOGIE } from "../../queries/anagraficaTipologieQuery.js";

export class EditFormTipologie extends React.Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    const id = this.props.id;
    return (
      <Query
        query={GET_TIPOLOGIE}
        variables={{ id }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>loading</div>;
          if (error) return `Error!: ${error}`;

          return <MutationFormTipologie id={id} data={data} />;
        }}
      </Query>
    );
  }
}

export default EditFormTipologie;
