import React from 'react'
import { Mutation } from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import { ApolloConsumer } from 'react-apollo'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import CardHeader from 'components/Card/CardHeader.jsx'
import CardText from 'components/Card/CardText.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import { UPDATE_VERIFICA } from '../../queries/verificheQuery.js'
import { GET_ALL_VERIFICHE } from '../../queries/verificheQuery.js'

import { withSnackbar } from 'notistack'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

class MutationFormVerifiche extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ...this.props.data[Object.keys(this.props.data)[0]]
    }
    
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleFormSubmit()
    }
  }
  verifyUrl (value) {
    try {
      new URL(value)
      return true
    } catch (_) {
      return false
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail (value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }
  // function that verifies if a string has a given length or not
  verifyLength (value, length) {
    if (value.length >= length) {
      return true
    }
    return false
  }
  // function that verifies if two strings are equal
  compare (string1, string2) {
    if (string1 === string2) {
      return true
    }
    return false
  }
  // function that verifies if value contains only numbers
  verifyNumber (value) {
    var numberRex = new RegExp('^[0-9]+$')
    if (numberRex.test(value)) {
      return true
    }
    return false
  }
  change (event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'checkbox':
        if (event.target.checked) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'min-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      default:
        break
    }
    switch (type) {
      case 'checkbox':
        this.setState({ [stateName]: event.target.checked })
        break
      default:
        this.setState({ [stateName]: event.target.value })
        break
    }
  }

  handleFormSubmit = () => {
    document
      .getElementById('editForm')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  }

  render () {
    const { classes } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' text>
              <CardText color='rose'>
                <h4 className={classes.cardTitle}>Modifica verifica</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <ApolloConsumer>
                {client => (
                  <Mutation
                    mutation={UPDATE_VERIFICA}
                    onCompleted={() => {
                      // this.props.data.refetch();
                      // this.setState({ descrizione: "" });
                      // this.setState({ codice_articolo: "" });
                      // const { key } = this.state;
                      // this.toggleEdit(key);
                      this.props.enqueueSnackbar('Update riuscito', {
                        variant: 'success',
                        autoHideDuration: 1000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    }}
                  >
                    {(updateQuery, { loading, error, data }) => {
                      return (
                        <form
                          id='editForm'
                          name='editForm'
                          onSubmit={e => {
                            e.preventDefault()
                            
                            updateQuery({
                              variables: {
                                id: `${this.state.Id}`,
                                userid: `${this.state.Userid}`,
                                rfid: `${this.state.Rfid}`,
                                matricola: `${this.state.Matricola}`,
                                stato: `${this.state.Stato}`,
                                tipo: `${this.state.Tipo}`,
                                date: Date.now().toString()
                              }
                              
                            } )
                              .then(response => {
                                
                                this.setState({
                                  ...response[Object.keys(response)[0]]
                                })
                                console.log(response)

                                client.query({
                                  query: GET_ALL_VERIFICHE,
                                  fetchPolicy: 'network-only'
                                })
                              })
                              .catch(error => {
                                this.props.enqueueSnackbar(
                                  `Errore connessione ${error}`,
                                  {
                                    variant: 'error',
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                      vertical: 'top',
                                      horizontal: 'center'
                                    }
                                  }
                                )
                              })
                          }} // on submit
                        >
                          <input type='hidden' value={`${this.state.Id}`} />
                          <CustomInput
                            labelText='RFID *'
                            id='rfid'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'Rfid', 'text'),
                              type: 'text',
                              value: `${this.state.Rfid}`,
                              onKeyPress: this.handleKeyPress
                            }}
                          />
                          <CustomInput
                            labelText='Matricola *'
                            id='matricola'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'Matricola', 'text'),
                              type: 'text',
                              value: `${this.state.Matricola}`,
                              onKeyPress: this.handleKeyPress
                            }}
                          />

                         
                          <CustomInput
                            labelText='Tipo *'
                            id='tipo'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'Tipo', 'text'),
                              type: 'text',
                              value: `${this.state.Tipo}`,
                              onKeyPress: this.handleKeyPress
                            }}
                          />
                        </form>
                      )
                    }}
                  </Mutation>
                )}
              </ApolloConsumer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button onClick={this.handleFormSubmit} color='rose'>
                Salva
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    ) // return
  } // render
}

export default withSnackbar(withStyles(styles)(MutationFormVerifiche))
