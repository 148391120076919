import { graphql } from "react-apollo";
import { GET_ALL_ARTICLES } from "../../queries/anagraficaArticoliQuery.js";

import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import EditForm from "components/EditForm/EditForm.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOpen: true,
      editOpen: false,
      key: 0,
      id: 0
    };
  }

  toggleEdit(key) {
    const { tableOpen, editOpen } = this.state;
    this.setState({ tableOpen: !tableOpen, editOpen: !editOpen, key: key });
    //if (!tableOpen) { this.props.data.refetch()}
  }

  render() {
    if (this.props.data.loading) return <div>Loading...</div>;

    const { tableOpen, editOpen } = this.state;
    //Editing
    if (!tableOpen && editOpen) {
      const { key } = this.state;
      const { classes } = this.props;
      const id = this.props.data.GetAllAnagrafica_articoli[key].Id;
      return (
        <div>
          {" "}
          <Button
            className={classes.marginRight}
            onClick={() => this.toggleEdit(key)}
          >
            <KeyboardArrowLeft className={classes.icons} /> Anagrafica articoli
          </Button>
          <EditForm id={id} />
        </div>
      );
    }

    //Normal view
    if (tableOpen && !editOpen) {
      const { classes } = this.props;
      const data = this.props.data.GetAllAnagrafica_articoli;

      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Anagrafica Articoli</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data.map((prop, key) => {
                    return {
                      id: prop.Id,
                      codice_articolo: prop.Codice_articolo,
                      descrizione: prop.Descrizione,
                      giacenza: prop.Giacenza,
                      revisione: prop.Revisione,
                      tipo_lamiera: prop.Tipo_lamiera,

                      actions: (
                        <div className="actions-right">
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.toggleEdit(key);
                            }}
                            color="warning"
                            className="edit"
                          >
                            <Dvr />
                          </Button>{" "}
                          {/* use this button to remove the data row */}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              var data = this.state.data;
                              data.find((o, i) => {
                                if (o.id === key) {
                                  // here you should add some custom code so you can delete the data
                                  // from this component and from your server as well
                                  data.splice(i, 1);
                                  return true;
                                }
                                return false;
                              });
                              this.setState({ data: data });
                            }}
                            color="danger"
                            className="remove"
                          >
                            <Close />
                          </Button>{" "}
                        </div>
                      )
                    };
                  })}
                  filterable
                  columns={[
                    {
                      Header: "Codice articolo",
                      accessor: "codice_articolo"
                    },
                    {
                      Header: "Descrizione",
                      accessor: "descrizione"
                    },
                    {
                      Header: "Giacenza",
                      accessor: "giacenza"
                    },
                    {
                      Header: "Revisione",
                      accessor: "revisione"
                    },
                    {
                      Header: "Tipo lamiera",
                      accessor: "tipo_lamiera"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ); // return
    } // if tableOpen
  }
}

export default graphql(GET_ALL_ARTICLES)(withStyles(styles)(ReactTables));
