import React from 'react'
import { Mutation, Query } from 'react-apollo'
import { graphql, compose } from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import { ApolloConsumer } from 'react-apollo'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import CardHeader from 'components/Card/CardHeader.jsx'
import CardText from 'components/Card/CardText.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import { GET_ALL_VERIFICHE } from '../../queries/verificheQuery.js'
import { INSERT_VERIFICA } from '../../queries/verificheQuery.js'
import { GET_ALL_INVENTORY } from '../../queries/inventarioQuery.js'

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx'

import { withSnackbar } from 'notistack'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  button: {
    backgroundColor: '#00ffff',
    borderColor: 'red',
    borderWidth: 5,
    borderRadius: 15
  }
}

const divStyle = {
  backgroundColor: 'green',
  
};

const divStyleRed = {
  backgroundColor: 'red',
  
};
class InsertFormVerifiche extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      Rfid: '',
      Fase: 0
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleFormSubmit()
    }
  }

  handleKeyPressRfid = e => {
    if (e.key === 'Enter') {
      this.handleFormSubmitRfid()
    }
  }

  verifyUrl (value) {
    try {
      new URL(value)
      return true
    } catch (_) {
      return false
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail (value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }
  // function that verifies if a string has a given length or not
  verifyLength (value, length) {
    if (value.length >= length) {
      return true
    }
    return false
  }
  // function that verifies if two strings are equal
  compare (string1, string2) {
    if (string1 === string2) {
      return true
    }
    return false
  }
  // function that verifies if value contains only numbers
  verifyNumber (value) {
    var numberRex = new RegExp('^[0-9]+$')
    if (numberRex.test(value)) {
      return true
    }
    return false
  }
  change (event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'checkbox':
        if (event.target.checked) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'min-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'max-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      default:
        break
    }
    switch (type) {
      case 'checkbox':
        this.setState({ [stateName]: event.target.checked })
        break
      default:
        this.setState({ [stateName]: event.target.value })
        break
    }
  }

  handleFormSubmit = () => {
    var elements = document.getElementById('editForm').elements
    var ok = true
    for (var i = 0, element; (element = elements[i++]);) {
      if (element.type === 'text' && element.value === '') {
        console.log('Elemento non trovato')
        ok = false
      }
    }

    if (!ok) {
      this.props.enqueueSnackbar(`Inserire tutti i dati`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return false
    }

    document
      .getElementById('editForm')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  }

  handleFormSubmitRfid = () => {
    var elements = document.getElementById('rfidForm').elements
    var ok = true
    for (var i = 0, element; (element = elements[i++]);) {
      if (element.type === 'text' && element.value === '') {
        console.log('Elemento non trovato')
        ok = false
      }
    }

    if (!ok) {
      this.props.enqueueSnackbar(`Inserire Rfid`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return false
    }

    this.setState({ Fase: 1 })
    document
      .getElementById('rfidForm')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  }

  handleAccept = () => {
    var rfidPresente = this.props.data.GetAllInventario.find(
      element => element.Rfid == this.state.Rfid
    )

    var date = Date.now().toString()
    this.props
      .mutate({
        variables: {
          date: date,
          matricola: rfidPresente.Matricola,
          rfid: rfidPresente.Rfid,
          stato: 0,
          tipo: rfidPresente.Tipo,
          userid: localStorage.getItem('userid')
        }
      })
      .then(() => {
        this.props.enqueueSnackbar(`Verifica inserita correttamente`, {
          variant: 'success',
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        this.setState({ Fase: 0, Rfid: '' })
      }) // Mutate
  }

  handleDiscard = () => {
    var rfidPresente = this.props.data.GetAllInventario.find(
      element => element.Rfid == this.state.Rfid
    )

    var date = Date.now().toString()
    this.props
      .mutate({
        variables: {
          date: date,
          matricola: rfidPresente.Matricola,
          rfid: rfidPresente.Rfid,
          stato: 1,
          tipo: rfidPresente.Tipo,
          userid: localStorage.getItem('userid')
        }
      })
      .then(() => {
        this.props.enqueueSnackbar(`Verifica inserita correttamente`, {
          variant: 'success',
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        this.setState({ Fase: 0, Rfid: '' })
      })
  }

  render () {
    const { classes } = this.props
    if (this.props.loading) return 'Loading'
    if (this.props.error) return 'Error'

    if (this.state.Fase == 0) {
      return (
        <form
          id='rfidForm'
          name='rfidForm'
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <CustomInput
            labelText='Rfid *'
            id='rfid'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'Rfid', 'text'),
              type: 'text',
              value: `${this.state.Rfid}`,
              onKeyPress: this.handleKeyPressRfid,
              autoFocus: true
            }}
          />
        </form>
      )
    }

    var rfidPresente = this.props.data.GetAllInventario.find(
      element => element.Rfid == this.state.Rfid
    )

    if (!rfidPresente) {
      this.props.enqueueSnackbar(`Rfid non trovato in inventario`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      this.setState({ Fase: 0, Rfid: '' })
      return false
    }

    console.log(rfidPresente)
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' text>
              <CardText color='rose'>
                Matricola
                <h4 className={classes.cardTitle}>{rfidPresente.Matricola}</h4>
              </CardText>
              <CardText color='rose'>
                Tipologia
                <h4 className={classes.cardTitle}>{rfidPresente.Tipo}</h4>
              </CardText>
              <CardText color='rose'>
                Rfid
                <h4 className={classes.cardTitle}>{rfidPresente.Rfid}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <ApolloConsumer>
                {client => (
                  <Mutation
                    mutation={INSERT_VERIFICA}
                    onCompleted={() => {
                      this.props.enqueueSnackbar('Inserimento riuscito', {
                        variant: 'success',
                        autoHideDuration: 1000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    }}
                  >
                    {(updateQuery, { loading, error, data }) => {
                      return (
                        <form
                          id='editForm'
                          name='editForm'
                          onSubmit={e => {
                            e.preventDefault()

                            updateQuery({
                              variables: {
                                rfid: `${this.state.Rfid}`
                              }
                            })
                              .then(response => {
                                client.query({
                                  query: GET_ALL_VERIFICHE,
                                  fetchPolicy: 'network-only'
                                })

                                this.setState({
                                  Tipo: '',
                                  Descrizione: ''
                                })
                              })
                              .catch(error => {
                                var err = ''
                                if (error.message.includes('connection')) {
                                  err = 'Errore connessione'
                                } else {
                                  if (error.message.includes('Duplicate')) {
                                    err = 'Presenza duplicato (Tipologia)'
                                  }
                                }

                                this.props.enqueueSnackbar(`${err}`, {
                                  variant: 'error',
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center'
                                  }
                                })
                              })
                          }} // on submit
                        />
                      )
                    }}
                  </Mutation>
                )}
              </ApolloConsumer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                onClick={this.handleAccept}
                style={divStyle}
                size='lg'
              >
                Accetta
              </Button>

              <Button onClick={this.handleDiscard}  style={divStyleRed} size='lg'>
                Rifiuta
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    ) // return
  } // render
}

export default withSnackbar(
  compose(
    graphql(GET_ALL_INVENTORY),
    graphql(INSERT_VERIFICA)
  )(withStyles(styles)(InsertFormVerifiche))
)
