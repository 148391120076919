//Next Gest Import
import ReactTablesAnagraficaArticoli from "views/Tables/AnagraficaArticoliTable.jsx";
import InserisciInventario from "views/Tables/InserisciInventario.jsx";
import VisualizzaInventario from "views/Tables/VisualizzaInventario.jsx";
import GestioneTipologie from "views/Tables/GestioneTipologie.jsx";
import InserisciTipologie from "views/Tables/InserisciTipologia.jsx";
import InserisciVerifica from "views/Tables/InserisciVerifica.jsx";
import VisualizzaVerifiche from "views/Tables/VisualizzaVerifiche.jsx";


import Buttons from "views/Components/Buttons.jsx";

import Charts from "views/Charts/Charts.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import ErrorPage from "views/Pages/ErrorPage.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Icons from "views/Components/Icons.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Panels from "views/Components/Panels.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import SweetAlert from "views/Components/SweetAlert.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import Typography from "views/Components/Typography.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import Wizard from "views/Forms/Wizard.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";

var dashRoutes = [
 
    {
        path: "/inserisci_inventario",
        name: "Inserisci inventario",
        rtlName: "طاولات عادية",
        mini: "IN",
        rtlMini: "صر",
        component: InserisciInventario,
        layout: "/admin",
        requiredLevel: 6
      },

      {
        path: "/visualizza_inventario",
        name: "Visualizza inventario",
        rtlName: "طاولات عادية",
        mini: "VI",
        rtlMini: "صر",
        component: VisualizzaInventario,
        layout: "/admin",
        requiredLevel: 6
      },

       {
        path: "/anagrafica_tipologie",
        name: "Anagrafica Tipologie",
        rtlName: "طاولات عادية",
        mini: "VI",
        rtlMini: "صر",
        component: GestioneTipologie,
        layout: "/admin",
        requiredLevel: 6
      },

       {
        path: "/inserisci_tipologia",
        name: "inserisci Tipologia",
        rtlName: "طاولات عادية",
        mini: "VI",
        rtlMini: "صر",
        component: InserisciTipologie,
        layout: "/admin",
        requiredLevel: 6
      },

      {
        path: "/inserisci_verifica",
        name: "Verifica trimestrale",
        rtlName: "طاولات عادية",
        mini: "VI",
        rtlMini: "صر",
        component: InserisciVerifica,
        layout: "/admin",
        requiredLevel: 6
      },

        {
        path: "/lista_verifiche",
        name: "Visualizza Verifiche",
        rtlName: "طاولات عادية",
        mini: "VI",
        rtlMini: "صر",
        component: VisualizzaVerifiche,
        layout: "/admin",
        requiredLevel: 6
      },

       {
        path: "/login-page",
        component: LoginPage,
        layout: "/auth",
        visible: false
      },

 
];
export default dashRoutes;
