import { graphql,Query } from 'react-apollo'
import { GET_ALL_VERIFICHE } from '../../queries/verificheQuery.js'
import { GET_ALL_USER } from '../../queries/userQuery.js'
// import checkUser from '../../queries/queries'

import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { withSnackbar } from 'notistack'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

import EditForm from 'components/EditForm/EditForm.js'

import VisualizzaVerificheConUtenti from 'views/Tables/VisualizzaVerificheConUtenti.jsx'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

class VisualizzaVerifiche extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tableOpen: true,
      editOpen: false,
      key: 0,
      id: 0
    }
  }

  toggleEdit (key) {
    const { tableOpen, editOpen } = this.state
    this.setState({ tableOpen: !tableOpen, editOpen: !editOpen, key: key })
    // if (!tableOpen) { this.props.data.refetch()}
  }

  correctDate (d) {
    return new Date(parseInt(d)).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
  }

 render () {
    if (this.props.data.loading) return <div>Loading...</div>
     const { classes } = this.props;
      
      return (
      <VisualizzaVerificheConUtenti users={this.props.data.GetAllUser}/>       
     
    ) // return
  }
}

export default graphql(GET_ALL_USER)(
  withStyles(styles)(VisualizzaVerifiche)
)
