import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import checkUser from '../../queries/queries'
import { Redirect } from 'react-router-dom'

// import { graphql } from "react-apollo";

import axios from 'axios'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
// @material-ui/icons
import Face from '@material-ui/icons/Face'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

import { withSnackbar } from 'notistack'

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      username: '',
      password: '',
      error: '',
      open: false,
      showPassword: false
    }
  }

 handleClickShowPassword = () => {
   this.setState({
     showPassword: !this.state.showPassword
   })
 }


  componentWillMount () {
    var token = localStorage.getItem('token')
    if (token) {
      console.log('Getting User from Token')
    } else {
      console.log('Token not found')
    }
  }

  componentDidMount () {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
  }
  componentWillUnmount () {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  redirect = (e, reason) => {
    this.props.history.push('/admin/inserisci_inventario')
  }

  onSubmit = e => {
    e.preventDefault()

    let config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }

    let { username, password } = this.state
    var postData = {
      username: username,
      password: password
    }

    axios
       .post("https://id.verpelli.it:58000/login", postData, config)
      //.post('http://localhost:8080/login', postData, config)
      .then(response => {
        let token = response.data.token
        let userid = response.data.userid
        if (token) {
          this.setState({ username: '', password: '' })
          localStorage.setItem('token', token)
          localStorage.setItem('userid', userid)
          this.props.enqueueSnackbar('Autenticazione riuscita', {
            variant: 'success',
            autoHideDuration: 1000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            onClose: this.redirect
          })
        } else {
          this.setState({ username: '', password: '' })
          this.props.enqueueSnackbar('Autenticazione fallita', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
      .catch(error => {
        this.setState({ username: '', password: '' })
        this.props.enqueueSnackbar(`Errore connessione ${error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      })
  }

  onChangeUser = e => {
    this.setState({ username: e.target.value })
  }

  onChangePassword = e => {
    this.setState({ password: e.target.value })
  }

  enableSubmit = () => {
    return !(this.state.username.length > 0 && this.state.password.length > 0)
  }

  render () {
    const { classes } = this.props
    const data = this.props.data
    console.log(data)

    if (data.loading) {
      return <div>Loading...</div>
    }

    if (!data.error) return <Redirect to='/admin/dashboard' />

    if (data.error) {
      return (
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.onSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color='info'
                  >
                    <h4 className={classes.cardTitle}>Log in </h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText='Username..'
                      id='username'
                      formControlProps={{
                        fullWidth: true
                      }}
                      onChange={this.onChangeUser}
                      inputProps={{
                        onChange: this.onChangeUser,
                        value: this.state.username,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        )
                      }}
                    />

                    <CustomInput
                      labelText='Password'
                      id='password'
                      formControlProps={{
                        fullWidth: true
                      }}
                      onChange={this.onChangePassword}
                      inputProps={{
                        onChange: this.onChangePassword,
                        value: this.state.password,
                        type: this.state.showPassword ? 'text' : 'password',
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={this.handleClickShowPassword}
                              //onMouseDown={handleMouseDownPassword}
                            >
                              {this.state.showPassword  ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      type='submit'
                      color='info'
                      onClick={this.onSubmit}
                      simple
                      size='lg'
                      block
                      disabled={this.enableSubmit()}
                    >
                      Conferma
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      )
    }
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default graphql(checkUser, {
  options: {
    variables: {
      token: localStorage.getItem('token')
    }
  }
})(withSnackbar(withStyles(loginPageStyle)(LoginPage)))
