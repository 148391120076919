import gql from 'graphql-tag'

export const INSERT_VERIFICA = gql`
  mutation InsertVerifica(
       $date: String!
       $matricola: String!
       $rfid: String!
       $stato: Int!
       $tipo: String!
       $userid: Int!
       )
      {
        CreateVerifiche(
          date: $date
          matricola: $matricola
          rfid: $rfid
          stato: $stato
          tipo: $tipo
          userid: $userid
        )
        {
          Id
        }
      }
`

export const UPDATE_VERIFICA = gql`
 mutation UpdateVerifica (
  $id: Int!
  $date: String!
  $matricola: String!
  $rfid: String!
  $stato: Int!
  $tipo: String!
  $userid: Int!

) { UpdateVerifiche(
  id: $id
  date: $date
  matricola: $matricola
  rfid: $rfid
  stato: $stato
  tipo: $tipo
  userid: $userid

  
) {
    Id
    Rfid
    Userid
    Matricola
    Stato
    Date
    Tipo
}

}
`;


export const DELETE_VERIFICHE = gql`
 mutation DeleteVerifiche (
  $id: Int!
  $date: String!
  $matricola: String!
  $rfid: String!
  $stato: Int!
  $tipo: String!
  $userid: Int!

) { DeleteVerifiche(
  id: $id
  date: $date
  matricola: $matricola
  rfid: $rfid
  stato: $stato
  tipo: $tipo
  userid: $userid

  
) {
    Id
    }

}
`;



export const GET_VERIFICA = gql`
query GetVerifica($id: Int!) {
  GetVerifiche(id: $id) {
    Id
    Rfid
    Userid
    Matricola
    Stato
    Date
    Tipo
  }
}
`;

export const GET_ALL_VERIFICHE = gql`
  {
    GetAllVerifiche {
    Id
    Rfid
    Userid
    Matricola
    Stato
    Date
    Tipo
  }
}
 
`
