import React from "react";
import { Query } from "react-apollo";

// core components

import MutationFormVerifiche from "components/EditForm/MutationFormVerifiche.js";

import { GET_VERIFICA } from "../../queries/verificheQuery.js";

export class EditFormVerifiche extends React.Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    const id = this.props.id;
    return (
      <Query
        query={GET_VERIFICA}
        variables={{ id }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>loading</div>;
          if (error) return `Error!: ${error}`;

          return <MutationFormVerifiche id={id} data={data} />;
        }}
      </Query>
    );
  }
}

export default EditFormVerifiche;
