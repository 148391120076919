import gql from "graphql-tag";

export const GET_ALL_ARTICLES = gql`
  {
    GetAllAnagrafica_articoli {
      Id
      Codice_articolo
      Descrizione
      Giacenza
      Revisione
      Tipo_lamiera
    }
  }
`;

export const GET_ARTICLE = gql`
  query GetArticolo($id: Int!) {
    GetAnagrafica_articoli(id: $id) {
      Id
      Codice_articolo
      Descrizione
      Giacenza
      Revisione
      Tipo_lamiera
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $id: Int!
    $codice_articolo: String
    $descrizione: String
  ) {
    UpdateAnagrafica_articoli(
      id: $id
      codice_articolo: $codice_articolo
      descrizione: $descrizione
    ) {
      Id
      Codice_articolo
      Descrizione
      Giacenza
      Revisione
      Tipo_lamiera
    }
  }
`;
